// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { WarehouseModel } from '../_models/warehouse.model';

export enum WarehouseActionTypes {
    WarehouseOnServerCreated = '[Edit Warehouse Dialog] Warehouse On Server Created',
    WarehouseCreated = '[Edit Warehouse Dialog] Warehouse Created',
    WarehouseUpdated = '[Edit Warehouse Dialog] Warehouse Updated',
    OneWarehouseDeleted = '[Warehouse List Page] One Warehouse Deleted',
    WarehousePageRequested = '[Warehouse List Page] Warehouse Page Requested',
    WarehousePageLoaded = '[Warehouse API] Warehouse Page Loaded',
    WarehousePageCancelled = '[Warehouse API] Warehouse Page Cancelled',
    WarehousePageToggleLoading = '[Warehouse] Warehouse Page Toggle Loading',
    WarehouseActionToggleLoading = '[Warehouse] Warehouse Action Toggle Loading'
}

export class WarehouseOnServerCreated implements Action {
    readonly type = WarehouseActionTypes.WarehouseOnServerCreated;
    constructor(public payload: { warehouse: WarehouseModel }) { }
}

export class WarehouseCreated implements Action {
    readonly type = WarehouseActionTypes.WarehouseCreated;
    constructor(public payload: { warehouse: WarehouseModel }) { }
}

export class WarehouseUpdated implements Action {
    readonly type = WarehouseActionTypes.WarehouseUpdated;
    constructor(public payload: { 
        WarehouseId: number,
        partialWarehouse: Update<WarehouseModel>, // For State update
        warehouse: WarehouseModel 
    }) { }
}

export class OneWarehouseDeleted implements Action {
    readonly type = WarehouseActionTypes.OneWarehouseDeleted;
    constructor(public payload: { WarehouseId: number }) { }
}

export class WarehousePageRequested implements Action {
    readonly type = WarehouseActionTypes.WarehousePageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class WarehousePageLoaded implements Action {
    readonly type = WarehouseActionTypes.WarehousePageLoaded;
    constructor(public payload: { warehouses: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class WarehousePageCancelled implements Action {
    readonly type = WarehouseActionTypes.WarehousePageCancelled;
}

export class WarehousePageToggleLoading implements Action {
    readonly type = WarehouseActionTypes.WarehousePageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class WarehouseActionToggleLoading implements Action {
    readonly type = WarehouseActionTypes.WarehouseActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type WarehouseActions = WarehouseOnServerCreated
    | WarehouseCreated
    | WarehouseUpdated
    | OneWarehouseDeleted
    | WarehousePageRequested
    | WarehousePageLoaded
    | WarehousePageCancelled
    | WarehousePageToggleLoading
    | WarehouseActionToggleLoading;
