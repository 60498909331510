// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, forkJoin } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/crud';

import { Global } from '../../../../shared/global';
// Models
import { ContractorRateModel } from '../_models/contractorRate.model';
import { mergeMap } from 'rxjs/operators';
import { each } from 'lodash';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';

const API_CUSTOMERS_URL = Global.BASE_CONTRACTOR_RATE_MASTER_ENDPOINT;
const API_URL = Global.BASE_CONTRACTOR_RATE_BY_COMPANYMID;

@Injectable()
export class ContractorRateService {

	loggedCompanyMId: number;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService,
		private sharedService: ShareddataserviceService) { }

	getLoggedCompanyMId() {
		this.sharedService.companydata.subscribe(data => this.loggedCompanyMId = data[0]["CompanyMId"]);
	}

	// CREATE =>  POST: add a new contractorRate to the server
	createContractorRate(contractorRate: ContractorRateModel): Observable<ContractorRateModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<ContractorRateModel>(API_CUSTOMERS_URL, contractorRate, { headers: httpHeaders });
	}

	uploadImage(imageFile: File, imageName: any): Observable<any> {
		let formData = new FormData();
		formData.append('ImageFile', imageFile, imageFile.name);
		formData.append('imageName', imageName);

		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'multipart/form-data');

		const url = "Global.BASE_UPLOAD_CATEGORY_IMAGE_ENDPOINT";
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post<any>(url, formData, { headers: httpHeaders });
	}

	// READ
	getAllContractorRates(): Observable<ContractorRateModel[]> {
		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<ContractorRateModel[]>(URL);
	}

	getContractorRateById(ContractorRateId: number): Observable<ContractorRateModel> {
		return this.http.get<ContractorRateModel>(API_CUSTOMERS_URL + `/${ContractorRateId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findContractorRates(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;

		return this.http.get<QueryResultsModel[]>(URL).pipe(
			mergeMap(res => {
				const result = this.httpUtils.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}

	// UPDATE => PUT: update the contractorRate on the server
	updateContractorRate(ContractorRateId: number, contractorRate: ContractorRateModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_CUSTOMERS_URL + ContractorRateId, contractorRate, { headers: httpHeader });
	}

	// UPDATE Status
	updateStatusForContractorRate(contractorRate: ContractorRateModel[], status: number): Observable<any> {
		const tasks$ = [];
		each(contractorRate, element => {
			const _reviews = Object.assign({}, element);
			_reviews.ProductRate = status;
			tasks$.push(this.updateContractorRate(_reviews.ContractorRateId, _reviews));
		});
		return forkJoin(tasks$);
	}

	// DELETE => delete the contractorRate from the server
	deleteContractorRate(ContractorRateId: number): Observable<ContractorRateModel> {
		const url = `${API_CUSTOMERS_URL}${ContractorRateId}`;
		return this.http.delete<ContractorRateModel>(url);
	}
}
