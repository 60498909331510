// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { WarehouseActions, WarehouseActionTypes } from '../_actions/warehouse.actions';
// Models
import { WarehouseModel } from '../_models/warehouse.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface WarehouseState extends EntityState<WarehouseModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedWarehouseId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<WarehouseModel> = createEntityAdapter<WarehouseModel>({
    selectId: warehouse => warehouse.WarehouseId
});

export const initialWarehouseState: WarehouseState = adapter.getInitialState({
    warehouseForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedWarehouseId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function warehouseReducer(state = initialWarehouseState, action: WarehouseActions): WarehouseState {
    switch (action.type) {
        case WarehouseActionTypes.WarehousePageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedWarehouseId: undefined
            };
        }
        case WarehouseActionTypes.WarehouseActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case WarehouseActionTypes.WarehouseOnServerCreated: return {
            ...state
        };
        case WarehouseActionTypes.WarehouseCreated: return adapter.addOne(action.payload.warehouse, {
            ...state, lastCreatedWarehouseId: action.payload.warehouse.WarehouseId
        });
        case WarehouseActionTypes.WarehouseUpdated: return adapter.updateOne(action.payload.partialWarehouse, state);
        case WarehouseActionTypes.OneWarehouseDeleted: return adapter.removeOne(action.payload.WarehouseId, state);
        case WarehouseActionTypes.WarehousePageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case WarehouseActionTypes.WarehousePageLoaded: {
            return adapter.addMany(action.payload.warehouses, {
                ...initialWarehouseState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getWarehouseState = createFeatureSelector<WarehouseModel>('warehouses');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
