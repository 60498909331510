import { BaseModel } from '../../../_base/crud';

export class IProductModel extends BaseModel {
    CompanyMId?: number;
    ProdCatCode?: number;
    ProdCatName: string;
    ProdCatAddInfo1: string;
    ProdCatAddInfo2: string;
    ProdCatAddInfo3?: number;
    ProdCatAddInfo4?: number;
    ProductRate: number;
    ProdCatMId: number;

    clear() {
        this.CompanyMId = undefined;
        this.ProdCatCode = undefined;
        this.ProdCatName = '';
        this.ProdCatAddInfo1 = '';
        this.ProdCatAddInfo2 = '';
        this.ProdCatAddInfo3 = null;
        this.ProdCatAddInfo4 = null;
        this.ProductRate = 0;
        this.ProdCatMId = undefined;
    }
}
