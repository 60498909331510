// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { ContractorState } from '../_reducers/contractor.reducers';
import { ContractorModel } from '../_models/contractor.model';

export const selectContractorState = createFeatureSelector<ContractorState>('contractors');

export const selectContractorById = (ContractorMId: number) => createSelector(
    selectContractorState,
    contractorState => contractorState.entities[ContractorMId]
);

export const selectContractorPageLoading = createSelector(
    selectContractorState,
    contractorState => contractorState.listLoading
);

export const selectContractorActionLoading = createSelector(
    selectContractorState,
    contractorState => contractorState.actionsloading
);

export const selectLastCreatedContractorMId = createSelector(
    selectContractorState,
    contractorState => contractorState.lastCreatedContractorMId
);

export const selectContractorShowInitWaitingMessage = createSelector(
    selectContractorState,
    contractorState => contractorState.showInitWaitingMessage
);

export const selectContractorInStore = createSelector(
    selectContractorState,
    contractorState => {
        const items: ContractorModel[] = [];
        each(contractorState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ContractorModel[] = httpExtension.sortArray(items, contractorState.lastQuery.sortField, contractorState.lastQuery.sortOrder);
        return new QueryResultsModel(result, contractorState.totalCount, '');
    }
);
