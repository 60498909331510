import { QueryParamsModel } from '../../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../../_base/crud';
// Services
import { SiteService } from '../_services/site.service';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';
// State
import { AppState } from '../../../../core/reducers';
// Actions
import {
    SiteActionTypes,
    SitePageRequested,
    SitePageLoaded,
    OneSiteDeleted,
    SiteActionToggleLoading,
    SitePageToggleLoading,
    SiteUpdated,
    SiteCreated,
    SiteOnServerCreated
} from '../_actions/site.actions';
import { of } from 'rxjs';

@Injectable()
export class SiteEffects {
    showPageLoadingDistpatcher = new SitePageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new SiteActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new SiteActionToggleLoading({ isLoading: false });

    @Effect()
    loadSitePage$ = this.actions$.pipe(
        ofType<SitePageRequested>(SiteActionTypes.SitePageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.siteService.findSites(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];

            this.sharedService.updateSiteList(result.items);
            //console.log(result.items);
            const pageLoadedDispatch = new SitePageLoaded({
                sites: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteSite$ = this.actions$
        .pipe(
            ofType<OneSiteDeleted>(SiteActionTypes.OneSiteDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.siteService.deleteSite(payload.SiteMId);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateSite$ = this.actions$
        .pipe(
            ofType<SiteUpdated>(SiteActionTypes.SiteUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.siteService.updateSite(payload.site.SiteMId, payload.site);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createSite$ = this.actions$
        .pipe(
            ofType<SiteOnServerCreated>(SiteActionTypes.SiteOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.siteService.createSite(payload.site).pipe(
                    tap(res => {
                        //console.log(res);
                        //const resValue = Object.keys(res).map(key => res[key]);
                        //console.log(resValue[0].value);
                        //this.store.dispatch(new SiteCreated({ site: res }));
                        if (res.SiteMId == 0) {
                            this.store.dispatch(new SiteCreated({ site: res }));
                        } else {
                            console.log('There is somthing wrong');
                            //this.store.dispatch(new SiteErrorMessage({ _errorMessage: resValue[0].value }));
                        }

                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private siteService: SiteService,
        private sharedService: ShareddataserviceService, private store: Store<AppState>) { }
}
