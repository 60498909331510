// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { DeliveryChallanActions, DeliveryChallanActionTypes } from '../_actions/deliveryChallan.actions';
// Models
import { DeliveryChallanModel } from '../_models/deliveryChallan.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface DeliveryChallanState extends EntityState<DeliveryChallanModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedDCMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<DeliveryChallanModel> = createEntityAdapter<DeliveryChallanModel>({
    selectId: deliveryChallan => deliveryChallan.DCMId
});

export const initialDeliveryChallanState: DeliveryChallanState = adapter.getInitialState({
    deliveryChallanForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedDCMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function deliveryChallanReducer(state = initialDeliveryChallanState, action: DeliveryChallanActions): DeliveryChallanState {
    switch (action.type) {
        case DeliveryChallanActionTypes.DeliveryChallanPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedDCMId: undefined
            };
        }
        case DeliveryChallanActionTypes.DeliveryChallanActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case DeliveryChallanActionTypes.DeliveryChallanOnServerCreated: return {
            ...state
        };
        case DeliveryChallanActionTypes.DeliveryChallanCreated: return adapter.addOne(action.payload.deliveryChallan, {
            ...state, lastCreatedDeliveryChallanId: action.payload.deliveryChallan.DCMId
        });
        case DeliveryChallanActionTypes.DeliveryChallanUpdated: return adapter.updateOne(action.payload.partialDeliveryChallan, state);
        case DeliveryChallanActionTypes.OneDeliveryChallanDeleted: return adapter.removeOne(action.payload.DCMId, state);
        case DeliveryChallanActionTypes.DeliveryChallanPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case DeliveryChallanActionTypes.DeliveryChallanPageLoaded: {
            return adapter.addMany(action.payload.deliveryChallans, {
                ...initialDeliveryChallanState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getDeliveryChallanState = createFeatureSelector<DeliveryChallanModel>('deliveryChallans');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
