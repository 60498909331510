// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { MinesMasterState } from '../_reducers/minesMaster.reducers';
import { MinesMasterModel } from '../_models/minesMaster.model';

export const selectMinesMasterState = createFeatureSelector<MinesMasterState>('minesMasters');

export const selectMinesMasterById = (MinesMId: number) => createSelector(
    selectMinesMasterState,
    minesMasterState => minesMasterState.entities[MinesMId]
);

export const selectMinesMasterPageLoading = createSelector(
    selectMinesMasterState,
    minesMasterState => minesMasterState.listLoading
);

export const selectMinesMasterActionLoading = createSelector(
    selectMinesMasterState,
    minesMasterState => minesMasterState.actionsloading
);

export const selectLastCreatedMinesMId = createSelector(
    selectMinesMasterState,
    minesMasterState => minesMasterState.lastCreatedMinesMId
);

export const selectMinesMasterShowInitWaitingMessage = createSelector(
    selectMinesMasterState,
    minesMasterState => minesMasterState.showInitWaitingMessage
);

export const selectMinesMasterInStore = createSelector(
    selectMinesMasterState,
    minesMasterState => {
        const items: MinesMasterModel[] = [];
        each(minesMasterState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: MinesMasterModel[] = httpExtension.sortArray(items, minesMasterState.lastQuery.sortField, minesMasterState.lastQuery.sortOrder);
        return new QueryResultsModel(result, minesMasterState.totalCount, '');
    }
);
