// Models and Consts
export { CustomerModel } from './_models/customer.model';

// DataSources
export { CustomerDataSource } from './_data-sources/customer.datasource';

// Actions
// Customer Actions =>
export {
    CustomerActionTypes,
    CustomerActions,
    CustomerOnServerCreated,
    CustomerCreated,
    CustomerUpdated,
    OneCustomerDeleted,
    CustomerPageRequested,
    CustomerPageLoaded,
    CustomerPageCancelled,
    CustomerPageToggleLoading
} from './_actions/customer.actions';


// Effects
export { CustomerEffects } from './_effects/customer.effects';

// Reducers
export { customerReducer } from './_reducers/customer.reducers';

// Selectors
// Customer selectors =>
export {
    selectCustomerById,
    selectCustomerInStore,
    selectCustomerPageLoading,
    selectLastCreatedCustomerMId,
    selectCustomerActionLoading,
    selectCustomerShowInitWaitingMessage
} from './_selectors/customer.selectors';

// Services
export { CustomerService } from './_services/customer.service';
