// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { CompanyMasterModel } from '../_models/companyMaster.model';

export enum CompanyMasterActionTypes {
    CompanyMasterOnServerCreated = '[Edit CompanyMaster Dialog] CompanyMaster On Server Created',
    CompanyMasterCreated = '[Edit CompanyMaster Dialog] CompanyMaster Created',
    CompanyMasterUpdated = '[Edit CompanyMaster Dialog] CompanyMaster Updated',
    OneCompanyMasterDeleted = '[CompanyMaster List Page] One CompanyMaster Deleted',
    CompanyMasterPageRequested = '[CompanyMaster List Page] CompanyMaster Page Requested',
    CompanyMasterPageLoaded = '[CompanyMaster API] CompanyMaster Page Loaded',
    CompanyMasterPageCancelled = '[CompanyMaster API] CompanyMaster Page Cancelled',
    CompanyMasterPageToggleLoading = '[CompanyMaster] CompanyMaster Page Toggle Loading',
    CompanyMasterActionToggleLoading = '[CompanyMaster] CompanyMaster Action Toggle Loading'
}

export class CompanyMasterOnServerCreated implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterOnServerCreated;
    constructor(public payload: { companyMaster: CompanyMasterModel }) { }
}

export class CompanyMasterCreated implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterCreated;
    constructor(public payload: { companyMaster: CompanyMasterModel }) { }
}

export class CompanyMasterUpdated implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterUpdated;
    constructor(public payload: { 
        CompanyMId: number,
        partialCompanyMaster: Update<CompanyMasterModel>, // For State update
        companyMaster: CompanyMasterModel 
    }) { }
}

export class OneCompanyMasterDeleted implements Action {
    readonly type = CompanyMasterActionTypes.OneCompanyMasterDeleted;
    constructor(public payload: { CompanyMId: number }) { }
}

export class CompanyMasterPageRequested implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class CompanyMasterPageLoaded implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterPageLoaded;
    constructor(public payload: { companyMasters: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class CompanyMasterPageCancelled implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterPageCancelled;
}

export class CompanyMasterPageToggleLoading implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class CompanyMasterActionToggleLoading implements Action {
    readonly type = CompanyMasterActionTypes.CompanyMasterActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type CompanyMasterActions = CompanyMasterOnServerCreated
    | CompanyMasterCreated
    | CompanyMasterUpdated
    | OneCompanyMasterDeleted
    | CompanyMasterPageRequested
    | CompanyMasterPageLoaded
    | CompanyMasterPageCancelled
    | CompanyMasterPageToggleLoading
    | CompanyMasterActionToggleLoading;
