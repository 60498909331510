// Models and Consts
export { IProductModel } from './models/product';

// DataSources
export { ProductsDataSource } from './_data-sources/product.datasource';

// Actions
// Product Actions =>
export {
    ProductActionTypes,
    ProductActions,
    ProductOnServerCreated,
    ProductCreated,
    ProductUpdated,
    OneProductDeleted,
    ManyProductsDeleted,
    ProductsPageRequested,
    ProductsPageLoaded,
    ProductsPageCancelled,
    ProductsPageToggleLoading
} from './_actions/Product.action';

// Effects
export { ProductEffects } from './_effects/product.effects';

// Reducers
export { productsReducer } from './_reducers/product.reducers';

// Selectors
// Product selectors =>
export {
    selectProductById,
    selectProductsInStore,
    selectProductsPageLoading,
    selectLastCreatedProductCatMId,
    selectProductsActionLoading,
    selectProductsShowInitWaitingMessage
} from './_selectors/product.selectors';

// Services
export { ProductsService } from './_services/product.service';