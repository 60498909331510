// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { SiteState } from '../_reducers/site.reducers';
import { SiteModel } from '../_models/site.model';

export const selectSiteState = createFeatureSelector<SiteState>('sites');

export const selectSiteById = (SiteMId: number) => createSelector(
    selectSiteState,
    siteState => siteState.entities[SiteMId]
);

export const selectSitePageLoading = createSelector(
    selectSiteState,
    siteState => siteState.listLoading
);

export const selectSiteActionLoading = createSelector(
    selectSiteState,
    siteState => siteState.actionsloading
);

export const selectLastCreatedSiteMId = createSelector(
    selectSiteState,
    siteState => siteState.lastCreatedSiteMId
);

export const selectSiteShowInitWaitingMessage = createSelector(
    selectSiteState,
    siteState => siteState.showInitWaitingMessage
);

export const selectSiteInStore = createSelector(
    selectSiteState,
    siteState => {
        const items: SiteModel[] = [];
        each(siteState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SiteModel[] = httpExtension.sortArray(items, siteState.lastQuery.sortField, siteState.lastQuery.sortOrder);
        return new QueryResultsModel(result, siteState.totalCount, '');
    }
);
