// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState } from '../../../core/reducers/';
import { isLoggedIn } from '../_selectors/auth.selectors';
import { ShareddataserviceService } from '../../../shared/shareddataservice.service';
import { ProductsService } from '../../masters/product-master';
import { ContractorService } from '../../masters/contractor';
import { CustomerService } from '../../masters/customer';
import { ContractorRateService } from '../../masters/contractorrate';
import { CustomerRateService } from '../../masters/customerrate';
import { SiteService } from '../../masters/site';
import { MinesMasterService } from '../../masters/minesmaster';
import { TransporterService } from '../../masters/transporter';
import { VehicleService } from '../../masters/vehicle';
import { DeliveryChallanService } from '../../transactions/deliverychallan';
import { InvoiceService } from '../../transactions/invoicetransaction';
import { WarehouseService } from '../../transactions/warehousetransaction';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private sharedService: ShareddataserviceService,
        private prouctService: ProductsService,
        private contractorService: ContractorService,
        private customerService: CustomerService,
        private contractorRateService: ContractorRateService,
        private customerRateService: CustomerRateService,
        private siteService: SiteService,
        private mineService: MinesMasterService,
        private transporterService: TransporterService,
        private vehicleService: VehicleService,
        private dcService: DeliveryChallanService,
        private invoiceService: InvoiceService,
        private warehouseService: WarehouseService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        //this.router.navigateByUrl('/auth/financial-year');
                        this.router.navigateByUrl('/auth/financial-year'); // Login
                    } else {
                        const loggedCompany = JSON.parse(sessionStorage.getItem('logedCompany'));
                        this.sharedService.onChangeCompanyData(loggedCompany);
                        this.getApiData();
                    }
                })
            );
    }

    getApiData() {
        this.prouctService.getAllProducts().subscribe(res => {
            this.sharedService.updateProductList(res);
        });

        this.contractorService.getAllContractors().subscribe(res => {
            this.sharedService.updateContractorList(res);
        });

        this.customerService.getAllCustomers().subscribe(res => {
            this.sharedService.updateCustomerList(res);
        });

        this.contractorRateService.getAllContractorRates().subscribe(res => {
            this.sharedService.updateContractorRateList(res);
        });

        this.customerRateService.getAllCustomerRates().subscribe(res => {
            this.sharedService.updateCustomerRateList(res);
        });

        this.siteService.getAllSites().subscribe(res => {
            this.sharedService.updateSiteList(res);
        });

        this.mineService.getAllMinesMasters().subscribe(res => {
            this.sharedService.updatemineList(res);
        });

        this.transporterService.getAllTransporters().subscribe(res => {
            this.sharedService.updateTransporterList(res);
        });

        this.vehicleService.getAllVehicles().subscribe(res => {
            this.sharedService.updateVehicleList(res);
        });

        this.dcService.getAllDeliveryChallans().subscribe(res => {
            this.sharedService.updateDeliveryChallanList(res);
        });

        this.invoiceService.getAllInvoices().subscribe(res => {
            this.sharedService.updateInvoiceList(res);
        });

        this.warehouseService.getAllWarehouses().subscribe(res => {
            this.sharedService.updateWarehouseList(res);
        });
    }
}
