import { QueryParamsModel } from '../../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../../_base/crud';
// Services
import { DeliveryChallanService } from '../_services/deliveryChallan.service';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';
// State
import { AppState } from '../../../../core/reducers';
// Actions
import {
    DeliveryChallanActionTypes,
    DeliveryChallanPageRequested,
    DeliveryChallanPageLoaded,
    OneDeliveryChallanDeleted,
    DeliveryChallanActionToggleLoading,
    DeliveryChallanPageToggleLoading,
    DeliveryChallanUpdated,
    DeliveryChallanCreated,
    DeliveryChallanOnServerCreated
} from '../_actions/deliveryChallan.actions';
import { of } from 'rxjs';

@Injectable()
export class DeliveryChallanEffects {
    showPageLoadingDistpatcher = new DeliveryChallanPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new DeliveryChallanActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new DeliveryChallanActionToggleLoading({ isLoading: false });

    @Effect()
    loadDeliveryChallanPage$ = this.actions$.pipe(
        ofType<DeliveryChallanPageRequested>(DeliveryChallanActionTypes.DeliveryChallanPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.deliveryChallanService.findDeliveryChallans(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];

            this.sharedService.updateDeliveryChallanList(result.items);
            //console.log(result.items);
            const pageLoadedDispatch = new DeliveryChallanPageLoaded({
                deliveryChallans: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteDeliveryChallan$ = this.actions$
        .pipe(
            ofType<OneDeliveryChallanDeleted>(DeliveryChallanActionTypes.OneDeliveryChallanDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.deliveryChallanService.deleteDeliveryChallan(payload.DCMId);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateDeliveryChallan$ = this.actions$
        .pipe(
            ofType<DeliveryChallanUpdated>(DeliveryChallanActionTypes.DeliveryChallanUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.deliveryChallanService.updateDeliveryChallan(payload.deliveryChallan.DCMId, payload.deliveryChallan);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createDeliveryChallan$ = this.actions$
        .pipe(
            ofType<DeliveryChallanOnServerCreated>(DeliveryChallanActionTypes.DeliveryChallanOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.deliveryChallanService.createDeliveryChallan(payload.deliveryChallan).pipe(
                    tap(res => {
                        //console.log(res);
                        //const resValue = Object.keys(res).map(key => res[key]);
                        //console.log(resValue[0].value);
                        //this.store.dispatch(new DeliveryChallanCreated({ deliveryChallan: res }));
                        if (res.DCMId == 0) {
                            this.store.dispatch(new DeliveryChallanCreated({ deliveryChallan: res }));
                        } else {
                            console.log('There is somthing wrong');
                            //this.store.dispatch(new DeliveryChallanErrorMessage({ _errorMessage: resValue[0].value }));
                        }

                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private deliveryChallanService: DeliveryChallanService,
        private sharedService: ShareddataserviceService, private store: Store<AppState>) { }
}
