// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { ContractorModel } from '../_models/contractor.model';

export enum ContractorActionTypes {
    ContractorOnServerCreated = '[Edit Contractor Dialog] Contractor On Server Created',
    ContractorCreated = '[Edit Contractor Dialog] Contractor Created',
    ContractorUpdated = '[Edit Contractor Dialog] Contractor Updated',
    OneContractorDeleted = '[Contractor List Page] One Contractor Deleted',
    ContractorPageRequested = '[Contractor List Page] Contractor Page Requested',
    ContractorPageLoaded = '[Contractor API] Contractor Page Loaded',
    ContractorPageCancelled = '[Contractor API] Contractor Page Cancelled',
    ContractorPageToggleLoading = '[Contractor] Contractor Page Toggle Loading',
    ContractorActionToggleLoading = '[Contractor] Contractor Action Toggle Loading'
}

export class ContractorOnServerCreated implements Action {
    readonly type = ContractorActionTypes.ContractorOnServerCreated;
    constructor(public payload: { contractor: ContractorModel }) { }
}

export class ContractorCreated implements Action {
    readonly type = ContractorActionTypes.ContractorCreated;
    constructor(public payload: { contractor: ContractorModel }) { }
}

export class ContractorUpdated implements Action {
    readonly type = ContractorActionTypes.ContractorUpdated;
    constructor(public payload: { 
        ContractorMId: number,
        partialContractor: Update<ContractorModel>, // For State update
        contractor: ContractorModel 
    }) { }
}

export class OneContractorDeleted implements Action {
    readonly type = ContractorActionTypes.OneContractorDeleted;
    constructor(public payload: { ContractorMId: number }) { }
}

export class ContractorPageRequested implements Action {
    readonly type = ContractorActionTypes.ContractorPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class ContractorPageLoaded implements Action {
    readonly type = ContractorActionTypes.ContractorPageLoaded;
    constructor(public payload: { contractors: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class ContractorPageCancelled implements Action {
    readonly type = ContractorActionTypes.ContractorPageCancelled;
}

export class ContractorPageToggleLoading implements Action {
    readonly type = ContractorActionTypes.ContractorPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class ContractorActionToggleLoading implements Action {
    readonly type = ContractorActionTypes.ContractorActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type ContractorActions = ContractorOnServerCreated
    | ContractorCreated
    | ContractorUpdated
    | OneContractorDeleted
    | ContractorPageRequested
    | ContractorPageLoaded
    | ContractorPageCancelled
    | ContractorPageToggleLoading
    | ContractorActionToggleLoading;
