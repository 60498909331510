import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../../_base/crud';
// State
import { AppState } from '../../../reducers';
import { selectContractorRateInStore, selectContractorRatePageLoading, selectContractorRateShowInitWaitingMessage } from '../_selectors/contractorRate.selectors';

export class ContractorRateDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectContractorRatePageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectContractorRateShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectContractorRateInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}
