// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { CustomerState } from '../_reducers/customer.reducers';
import { CustomerModel } from '../_models/customer.model';

export const selectCustomerState = createFeatureSelector<CustomerState>('customers');

export const selectCustomerById = (CustomerMId: number) => createSelector(
    selectCustomerState,
    customerState => customerState.entities[CustomerMId]
);

export const selectCustomerPageLoading = createSelector(
    selectCustomerState,
    customerState => customerState.listLoading
);

export const selectCustomerActionLoading = createSelector(
    selectCustomerState,
    customerState => customerState.actionsloading
);

export const selectLastCreatedCustomerMId = createSelector(
    selectCustomerState,
    customerState => customerState.lastCreatedCustId
);

export const selectCustomerShowInitWaitingMessage = createSelector(
    selectCustomerState,
    customerState => customerState.showInitWaitingMessage
);

export const selectCustomerInStore = createSelector(
    selectCustomerState,
    customerState => {
        const items: CustomerModel[] = [];
        each(customerState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CustomerModel[] = httpExtension.sortArray(items, customerState.lastQuery.sortField, customerState.lastQuery.sortOrder);
        return new QueryResultsModel(result, customerState.totalCount, '');
    }
);
