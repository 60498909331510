// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { InvoiceState } from '../_reducers/invoice.reducers';
import { InvoiceModel } from '../_models/invoice.model';

export const selectInvoiceState = createFeatureSelector<InvoiceState>('invoices');

export const selectInvoiceById = (InvoiceId: number) => createSelector(
    selectInvoiceState,
    invoiceState => invoiceState.entities[InvoiceId]
);

export const selectInvoicePageLoading = createSelector(
    selectInvoiceState,
    invoiceState => invoiceState.listLoading
);

export const selectInvoiceActionLoading = createSelector(
    selectInvoiceState,
    invoiceState => invoiceState.actionsloading
);

export const selectLastCreatedInvoiceId = createSelector(
    selectInvoiceState,
    invoiceState => invoiceState.lastCreatedInvoiceId
);

export const selectInvoiceShowInitWaitingMessage = createSelector(
    selectInvoiceState,
    invoiceState => invoiceState.showInitWaitingMessage
);

export const selectInvoiceInStore = createSelector(
    selectInvoiceState,
    invoiceState => {
        const items: InvoiceModel[] = [];
        each(invoiceState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: InvoiceModel[] = httpExtension.sortArray(items, invoiceState.lastQuery.sortField, invoiceState.lastQuery.sortOrder);
        return new QueryResultsModel(result, invoiceState.totalCount, '');
    }
);
