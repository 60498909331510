// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { CompanyMasterState } from '../_reducers/companyMaster.reducers';
import { CompanyMasterModel } from '../_models/companyMaster.model';

export const selectCompanyMasterState = createFeatureSelector<CompanyMasterState>('companyMasters');

export const selectCompanyMasterById = (CompanyMasterMId: number) => createSelector(
    selectCompanyMasterState,
    companyMasterState => companyMasterState.entities[CompanyMasterMId]
);

export const selectCompanyMasterPageLoading = createSelector(
    selectCompanyMasterState,
    companyMasterState => companyMasterState.listLoading
);

export const selectCompanyMasterActionLoading = createSelector(
    selectCompanyMasterState,
    companyMasterState => companyMasterState.actionsloading
);

export const selectLastCreatedCompanyMasterMId = createSelector(
    selectCompanyMasterState,
    companyMasterState => companyMasterState.lastCreatedCompanyMId
);

export const selectCompanyMasterShowInitWaitingMessage = createSelector(
    selectCompanyMasterState,
    companyMasterState => companyMasterState.showInitWaitingMessage
);

export const selectCompanyMasterInStore = createSelector(
    selectCompanyMasterState,
    companyMasterState => {
        const items: CompanyMasterModel[] = [];
        each(companyMasterState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CompanyMasterModel[] = httpExtension.sortArray(items, companyMasterState.lastQuery.sortField, companyMasterState.lastQuery.sortOrder);
        return new QueryResultsModel(result, companyMasterState.totalCount, '');
    }
);
