// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// RxJS
import { Observable, forkJoin, of } from 'rxjs';
import { mergeMap, delay } from 'rxjs/operators';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/crud';
// Models
import { IProductModel } from '../models/product';

import { Global } from '../../../../shared/global';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';

const API_PRODUCTS_URL = Global.BASE_PRODUCT_MASTER_ENDPOINT;
const API_URL = Global.BASE_PRODUCT_BY_COMPANYMID;
@Injectable()
export class ProductsService {
	loggedCompanyMId: number;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService,
		private sharedService: ShareddataserviceService) { }

	getLoggedCompanyMId() {
		this.sharedService.companydata.subscribe(data => this.loggedCompanyMId = data[0]["CompanyMId"]);
	}

	// CREATE =>  POST: add a new Product to the server
	createProduct(product: IProductModel): Observable<IProductModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<IProductModel>(API_PRODUCTS_URL, product, { headers: httpHeaders });
	}

	// READ
	getAllProducts(): Observable<IProductModel[]> {
		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<IProductModel[]>(URL);
	}

	getProductById(productMId: number): Observable<IProductModel> {
		return this.http.get<IProductModel>(API_PRODUCTS_URL + `/${productMId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findProducts(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<QueryResultsModel[]>(URL).pipe(
				mergeMap(res => {
					const result = this.httpUtils.baseFilter(res, queryParams);
					return of(result);
				})
			);
		
	}

	// UPDATE => PUT: update the Product on the server
	updateProduct(ProdCatMId: number, product: IProductModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_PRODUCTS_URL + ProdCatMId, product, { headers: httpHeader });
	}

	// DELETE => delete the Product from the server
	deleteProduct(ProductMId: number): Observable<IProductModel> {
		const url = `${API_PRODUCTS_URL}${ProductMId}`;
		return this.http.delete<IProductModel>(url);
	}

	deleteProducts(prodCatMIds: number[] = []): Observable<any> {
		const url = API_PRODUCTS_URL + 'deleteCustomers';
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const body = { ProdCatMIdsForDelete: prodCatMIds };
		return this.http.put<QueryResultsModel>(url, body, { headers: httpHeaders });
	}
}