// Models and Consts
export { VehicleModel } from './_models/vehicle.model';

// DataSources
export { VehicleDataSource } from './_data-sources/vehicle.datasource';

// Actions
// Vehicle Actions =>
export {
    VehicleActionTypes,
    VehicleActions,
    VehicleOnServerCreated,
    VehicleCreated,
    VehicleUpdated,
    OneVehicleDeleted,
    VehiclePageRequested,
    VehiclePageLoaded,
    VehiclePageCancelled,
    VehiclePageToggleLoading
} from './_actions/vehicle.actions';


// Effects
export { VehicleEffects } from './_effects/vehicle.effects';

// Reducers
export { vehicleReducer } from './_reducers/vehicle.reducers';

// Selectors
// Vehicle selectors =>
export {
    selectVehicleById,
    selectVehicleInStore,
    selectVehiclePageLoading,
    selectLastCreatedVehicleMId,
    selectVehicleActionLoading,
    selectVehicleShowInitWaitingMessage
} from './_selectors/vehicle.selectors';

// Services
export { VehicleService } from './_services/vehicle.service';
