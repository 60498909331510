// Angular
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// RxJS
import { tap, withLatestFrom, filter, mergeMap } from 'rxjs/operators';
import { of, Observable, defer } from 'rxjs';
// NGRX
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
// Auth actions
import { AuthActionTypes, Login, Logout, Register, UserRequested, UserLoaded } from '../_actions/auth.actions';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';

@Injectable()
export class AuthEffects {
    @Effect({ dispatch: false })
    login$ = this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap(action => {
            sessionStorage.setItem('userID', action.payload.userID.toString());
            sessionStorage.setItem('token', action.payload.authToken);
            sessionStorage.setItem('logedCompany', JSON.stringify(action.payload.logedCompany));
            this.store.dispatch(new UserRequested());
        }),
    );

    @Effect({ dispatch: false })
    LogInFailure: Observable<any> = this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_FAILURE)
    );

    @Effect({ dispatch: false })
    logout$ = this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('logedCompany');
            sessionStorage.removeItem('isCoreRole');
            sessionStorage.removeItem('userID');
            //this.router.navigateByUrl('/auth/financial-year');
            this.router.navigateByUrl('/auth/financial-year'); // Login
        })
    );

    @Effect({ dispatch: false })
    register$ = this.actions$.pipe(
        ofType<Register>(AuthActionTypes.Register),
        tap(action => {
            sessionStorage.setItem('token', action.payload.authToken);
        })
    );

    @Effect({ dispatch: false })
    loadUser$ = this.actions$
        .pipe(
            ofType<UserRequested>(AuthActionTypes.UserRequested),
            withLatestFrom(this.store.pipe(select(isUserLoaded))),
            filter(([action, _isUserLoaded]) => !_isUserLoaded),
            mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
            tap(_user => {
                if (_user) {
                    if (_user.Role == 'Administrator') {
                        _user.roles = [];
                        _user.roles.push(1);
                        sessionStorage.setItem('isCoreRole', 'true');
                    } else if (_user.Role == 'DispatchUser') {
                        _user.roles = [];
                        _user.roles.push(2);
                        sessionStorage.setItem('isCoreRole', 'false');
                    }
                    else if (_user.Role == 'WarehouseUser') {
                        _user.roles = [];
                        _user.roles.push(3);
                        sessionStorage.setItem('isCoreRole', 'false');
                    }
                    this.store.dispatch(new UserLoaded({ user: _user }));
                } else {
                    this.store.dispatch(new Logout());
                }
            })
        );

    @Effect()
    init$: Observable<Action> = defer(() => {
        const userToken = sessionStorage.getItem('token');
        const logedCompany = sessionStorage.getItem('logedCompany');
        const userid = sessionStorage.getItem('userID');
        let observableResult = of({ type: 'NO_ACTION' });
        if (userToken) {
            observableResult = of(new Login({ userID: +userid, authToken: userToken, logedCompany: JSON.parse(logedCompany) }));
        }
        return observableResult;
    });

    private returnUrl: string;

    constructor(private actions$: Actions,
        private router: Router,
        private auth: AuthService,
        private store: Store<AppState>) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.returnUrl = event.url;
            }
        });
    }
}
