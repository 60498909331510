import { BaseModel } from '../../../_base/crud';

export class InvoiceModel extends BaseModel {
	InvoiceId: number;
	CompanyMId?: number;
	BillType: string;
	InvoiceNo?: number;
	InvoiceDate?: Date;
	POId: string;
	PODate?: Date;
	CustId?: number;
	Destination: string;
	ProdMId?: number;
	CompanyWgt?: number;
	Rate?: number;
	Total?: number;
	VatMode?: number;
	VatPer?: number;
	VatRs?: number;
	VatTotRs?: number;
	AddVatMode?: number;
	AddVatPer?: number;
	AddVatRs?: number;
	AddVatTotRs?: number;
	CSTMode?: number;
	CSTPer?: number;
	CSTRs?: number;
	CSTTotRs?: number;
	GrandTotal?: number;
	PayTermDay: string;
	PayDueDate?: Date;
	CustomerVNo?: number;
	SrNo?: number;
	AddWithDistrict: string;
	VehicleNo: string;
	DriverName: string;
	TransportMId?: number;
	ChallanNo?: number;
	RoyltOnMt?: number;
	RoyltDMF?: number;
	Remark1: string;
	NoofBags?: number;
	PerUnit?: number;
	ByBagRate?: boolean;
	DriverNo: string;
	DriverAdvance: number;

	clear() {
		this.CompanyMId = undefined;
		this.BillType= 'Retail Invoice';
		this.InvoiceNo = undefined;
		this.InvoiceDate = new Date;
		this.POId= '';
		this.PODate = new Date;
		this.CustId = undefined;
		this.Destination= '';
		this.ProdMId = undefined;
		this.CompanyWgt = 0;
		this.Rate = 0.000;
		this.Total = 0.000;
		this.VatMode = 0;
		this.VatPer = 0;
		this.VatRs = 0.000;
		this.VatTotRs = 0.000;
		this.AddVatMode = 0;
		this.AddVatPer = 5;
		this.AddVatRs = 0.000;
		this.AddVatTotRs = 0.000;
		this.CSTMode = 0;
		this.CSTPer = 0;
		this.CSTRs = 0.00;
		this.CSTTotRs = 0.000;
		this.GrandTotal = 0.000;
		this.PayTermDay= '';
		this.PayDueDate = undefined;
		this.CustomerVNo = undefined;
		this.SrNo = undefined;
		this.AddWithDistrict= '';
		this.VehicleNo= '';
		this.DriverName= '';
		this.TransportMId = undefined;
		this.ChallanNo = undefined;
		this.RoyltOnMt = undefined;
		this.RoyltDMF = undefined;
		this.Remark1= 'Rate inclusive of Royalty @ Rs.45 P.M.T & DMF @ 10% of';
		this.NoofBags = undefined;
		this.PerUnit = undefined;
		this.ByBagRate = undefined;
		this.DriverNo = '';
		this.DriverAdvance = 0;
	}
}
