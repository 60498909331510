// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ProductActions, ProductActionTypes } from '../_actions/product.action';
// Models
import { IProductModel } from '../models/product';
import { QueryParamsModel } from '../../../_base/crud';

export interface ProductsState extends EntityState<IProductModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedProdCatMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<IProductModel> = createEntityAdapter<IProductModel>({
    selectId: product => product.ProdCatMId
});

export const initialProductsState: ProductsState = adapter.getInitialState({
    ProductForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedProdCatMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function productsReducer(state = initialProductsState, action: ProductActions): ProductsState {
    switch (action.type) {
        case ProductActionTypes.ProductsPageToggleLoading:
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedProdCatMId: undefined
            };
        case ProductActionTypes.ProductActionToggleLoading:
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        case ProductActionTypes.ProductOnServerCreated: return {
            ...state
        };
        case ProductActionTypes.ProductCreated: return adapter.addOne(action.payload.product, {
            ...state, lastCreatedProductCatMId: action.payload.product.ProdCatMId
        });
        case ProductActionTypes.ProductUpdated: return adapter.updateOne(action.payload.partialProduct, state);
        case ProductActionTypes.OneProductDeleted: return adapter.removeOne(action.payload.id, state);
        case ProductActionTypes.ManyProductsDeleted: return adapter.removeMany(action.payload.ids, state);
        case ProductActionTypes.ProductsPageCancelled:
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        case ProductActionTypes.ProductsPageLoaded: {            
            return adapter.addMany(action.payload.products, {
                ...initialProductsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false,
            });
        }
        default: return state;
    }
}

export const getProductState = createFeatureSelector<IProductModel>('products');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();