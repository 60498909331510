// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { InvoiceModel } from '../_models/invoice.model';

export enum InvoiceActionTypes {
    InvoiceOnServerCreated = '[Edit Invoice Dialog] Invoice On Server Created',
    InvoiceCreated = '[Edit Invoice Dialog] Invoice Created',
    InvoiceUpdated = '[Edit Invoice Dialog] Invoice Updated',
    OneInvoiceDeleted = '[Invoice List Page] One Invoice Deleted',
    InvoicePageRequested = '[Invoice List Page] Invoice Page Requested',
    InvoicePageLoaded = '[Invoice API] Invoice Page Loaded',
    InvoicePageCancelled = '[Invoice API] Invoice Page Cancelled',
    InvoicePageToggleLoading = '[Invoice] Invoice Page Toggle Loading',
    InvoiceActionToggleLoading = '[Invoice] Invoice Action Toggle Loading'
}

export class InvoiceOnServerCreated implements Action {
    readonly type = InvoiceActionTypes.InvoiceOnServerCreated;
    constructor(public payload: { invoice: InvoiceModel }) { }
}

export class InvoiceCreated implements Action {
    readonly type = InvoiceActionTypes.InvoiceCreated;
    constructor(public payload: { invoice: InvoiceModel }) { }
}

export class InvoiceUpdated implements Action {
    readonly type = InvoiceActionTypes.InvoiceUpdated;
    constructor(public payload: { 
        InvoiceId: number,
        partialInvoice: Update<InvoiceModel>, // For State update
        invoice: InvoiceModel 
    }) { }
}

export class OneInvoiceDeleted implements Action {
    readonly type = InvoiceActionTypes.OneInvoiceDeleted;
    constructor(public payload: { InvoiceId: number }) { }
}

export class InvoicePageRequested implements Action {
    readonly type = InvoiceActionTypes.InvoicePageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class InvoicePageLoaded implements Action {
    readonly type = InvoiceActionTypes.InvoicePageLoaded;
    constructor(public payload: { invoices: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class InvoicePageCancelled implements Action {
    readonly type = InvoiceActionTypes.InvoicePageCancelled;
}

export class InvoicePageToggleLoading implements Action {
    readonly type = InvoiceActionTypes.InvoicePageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class InvoiceActionToggleLoading implements Action {
    readonly type = InvoiceActionTypes.InvoiceActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type InvoiceActions = InvoiceOnServerCreated
    | InvoiceCreated
    | InvoiceUpdated
    | OneInvoiceDeleted
    | InvoicePageRequested
    | InvoicePageLoaded
    | InvoicePageCancelled
    | InvoicePageToggleLoading
    | InvoiceActionToggleLoading;
