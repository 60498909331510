// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { UserMasterState } from '../_reducers/userMaster.reducers';
import { UserMasterModel } from '../_models/userMaster.model';

export const selectUserMasterState = createFeatureSelector<UserMasterState>('userMasters');

export const selectUserMasterById = (UserId: number) => createSelector(
    selectUserMasterState,
    userMasterState => userMasterState.entities[UserId]
);

export const selectUserMasterPageLoading = createSelector(
    selectUserMasterState,
    userMasterState => userMasterState.listLoading
);

export const selectUserMasterActionLoading = createSelector(
    selectUserMasterState,
    userMasterState => userMasterState.actionsloading
);

export const selectLastCreatedUserId = createSelector(
    selectUserMasterState,
    userMasterState => userMasterState.lastCreatedUserId
);

export const selectUserMasterShowInitWaitingMessage = createSelector(
    selectUserMasterState,
    userMasterState => userMasterState.showInitWaitingMessage
);

export const selectUserMasterInStore = createSelector(
    selectUserMasterState,
    userMasterState => {
        const items: UserMasterModel[] = [];
        each(userMasterState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: UserMasterModel[] = httpExtension.sortArray(items, userMasterState.lastQuery.sortField, userMasterState.lastQuery.sortOrder);
        return new QueryResultsModel(result, userMasterState.totalCount, '');
    }
);
