import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../../_base/crud';
// State
import { AppState } from '../../../reducers';
import { selectCustomerRateInStore, selectCustomerRatePageLoading, selectCustomerRateShowInitWaitingMessage } from '../_selectors/customerRate.selectors';

export class CustomerRateDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectCustomerRatePageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectCustomerRateShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectCustomerRateInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}
