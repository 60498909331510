import { Action } from '@ngrx/store';
import { User } from '../_models/user.model';
import { UserMasterModel } from '../../masters/usermaster';
import { CompanyMasterModel } from '../../masters/companymaster';

export enum AuthActionTypes {
    Login = '[Login] Action',
    Logout = '[Logout] Action',
    Register = '[Register] Action',
    UserRequested = '[Request User] Action',
    UserLoaded = '[Load User] Auth API',
    LOGIN_SUCCESS = '[Auth] Login Success',
    LOGIN_FAILURE = '[Auth] Login Failure',
}

export class Login implements Action {
    readonly type = AuthActionTypes.Login;
    constructor(public payload: { userID: number, authToken: string, logedCompany: CompanyMasterModel[] }) { }
}

export class LogInFailure implements Action {
    readonly type = AuthActionTypes.LOGIN_FAILURE;
    constructor(public payload: any) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.Logout;
}

export class Register implements Action {
    readonly type = AuthActionTypes.Register;
    constructor(public payload: { authToken: string }) { }
}


export class UserRequested implements Action {
    readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
    readonly type = AuthActionTypes.UserLoaded;
    constructor(public payload: { user: User }) { }
}



export type AuthActions = Login | LogInFailure | Logout | Register | UserRequested | UserLoaded;
