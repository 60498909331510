// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { CompanyMasterActions, CompanyMasterActionTypes } from '../_actions/companyMaster.actions';
// Models
import { CompanyMasterModel } from '../_models/companyMaster.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface CompanyMasterState extends EntityState<CompanyMasterModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedCompanyMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CompanyMasterModel> = createEntityAdapter<CompanyMasterModel>({
    selectId: companyMaster => companyMaster.CompanyMId
});

export const initialCompanyMasterState: CompanyMasterState = adapter.getInitialState({
    companyMasterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedCompanyMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function companyMasterReducer(state = initialCompanyMasterState, action: CompanyMasterActions): CompanyMasterState {
    switch (action.type) {
        case CompanyMasterActionTypes.CompanyMasterPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCompanyMId: undefined
            };
        }
        case CompanyMasterActionTypes.CompanyMasterActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case CompanyMasterActionTypes.CompanyMasterOnServerCreated: return {
            ...state
        };
        case CompanyMasterActionTypes.CompanyMasterCreated: return adapter.addOne(action.payload.companyMaster, {
            ...state, lastCreatedCompanyMasterId: action.payload.companyMaster.CompanyMId
        });
        case CompanyMasterActionTypes.CompanyMasterUpdated: return adapter.updateOne(action.payload.partialCompanyMaster, state);
        case CompanyMasterActionTypes.OneCompanyMasterDeleted: return adapter.removeOne(action.payload.CompanyMId, state);
        case CompanyMasterActionTypes.CompanyMasterPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case CompanyMasterActionTypes.CompanyMasterPageLoaded: {
            return adapter.addMany(action.payload.companyMasters, {
                ...initialCompanyMasterState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getCompanyMasterState = createFeatureSelector<CompanyMasterModel>('companyMasters');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
