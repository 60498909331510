// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { VehicleModel } from '../_models/vehicle.model';

export enum VehicleActionTypes {
    VehicleOnServerCreated = '[Edit Vehicle Dialog] Vehicle On Server Created',
    VehicleCreated = '[Edit Vehicle Dialog] Vehicle Created',
    VehicleUpdated = '[Edit Vehicle Dialog] Vehicle Updated',
    OneVehicleDeleted = '[Vehicle List Page] One Vehicle Deleted',
    VehiclePageRequested = '[Vehicle List Page] Vehicle Page Requested',
    VehiclePageLoaded = '[Vehicle API] Vehicle Page Loaded',
    VehiclePageCancelled = '[Vehicle API] Vehicle Page Cancelled',
    VehiclePageToggleLoading = '[Vehicle] Vehicle Page Toggle Loading',
    VehicleActionToggleLoading = '[Vehicle] Vehicle Action Toggle Loading'
}

export class VehicleOnServerCreated implements Action {
    readonly type = VehicleActionTypes.VehicleOnServerCreated;
    constructor(public payload: { vehicle: VehicleModel }) { }
}

export class VehicleCreated implements Action {
    readonly type = VehicleActionTypes.VehicleCreated;
    constructor(public payload: { vehicle: VehicleModel }) { }
}

export class VehicleUpdated implements Action {
    readonly type = VehicleActionTypes.VehicleUpdated;
    constructor(public payload: { 
        VehicleMId: number,
        partialVehicle: Update<VehicleModel>, // For State update
        vehicle: VehicleModel 
    }) { }
}

export class OneVehicleDeleted implements Action {
    readonly type = VehicleActionTypes.OneVehicleDeleted;
    constructor(public payload: { VehicleMId: number }) { }
}

export class VehiclePageRequested implements Action {
    readonly type = VehicleActionTypes.VehiclePageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class VehiclePageLoaded implements Action {
    readonly type = VehicleActionTypes.VehiclePageLoaded;
    constructor(public payload: { vehicles: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class VehiclePageCancelled implements Action {
    readonly type = VehicleActionTypes.VehiclePageCancelled;
}

export class VehiclePageToggleLoading implements Action {
    readonly type = VehicleActionTypes.VehiclePageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class VehicleActionToggleLoading implements Action {
    readonly type = VehicleActionTypes.VehicleActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type VehicleActions = VehicleOnServerCreated
    | VehicleCreated
    | VehicleUpdated
    | OneVehicleDeleted
    | VehiclePageRequested
    | VehiclePageLoaded
    | VehiclePageCancelled
    | VehiclePageToggleLoading
    | VehicleActionToggleLoading;
