// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/crud';

import { Global } from '../../../../shared/global';
// Models
import { MinesMasterModel } from '../_models/minesMaster.model';
import { mergeMap } from 'rxjs/operators';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';

const API_CUSTOMERS_URL = Global.BASE_MINE_MASTER_ENDPOINT;
const API_URL = Global.BASE_MINE_BY_COMPANYMID;

@Injectable()
export class MinesMasterService {
	loggedCompanyMId: number;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService,
		private sharedService: ShareddataserviceService) { }

	getLoggedCompanyMId() {
		this.sharedService.companydata.subscribe(data => this.loggedCompanyMId = data[0]["CompanyMId"]);
	}

	// CREATE =>  POST: add a new minesMaster to the server
	createMinesMaster(minesMaster: MinesMasterModel): Observable<MinesMasterModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<MinesMasterModel>(API_CUSTOMERS_URL, minesMaster, { headers: httpHeaders });
	}

	uploadImage(imageFile: File, imageName: any): Observable<any> {
		let formData = new FormData();
		formData.append('ImageFile', imageFile, imageFile.name);
		formData.append('imageName', imageName);

		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'multipart/form-data');

		const url = "Global.BASE_UPLOAD_CATEGORY_IMAGE_ENDPOINT";
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post<any>(url, formData, { headers: httpHeaders });
	}

	// READ
	getAllMinesMasters(): Observable<MinesMasterModel[]> {
		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<MinesMasterModel[]>(URL);
	}

	getMinesMasterById(MinesMId: number): Observable<MinesMasterModel> {
		return this.http.get<MinesMasterModel>(API_CUSTOMERS_URL + `/${MinesMId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findMinesMasters(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<QueryResultsModel[]>(URL).pipe(
			mergeMap(res => {
				const result = this.httpUtils.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}

	// UPDATE => PUT: update the minesMaster on the server
	updateMinesMaster(MinesMId: number, minesMaster: MinesMasterModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_CUSTOMERS_URL + MinesMId, minesMaster, { headers: httpHeader });
	}

	// DELETE => delete the minesMaster from the server
	deleteMinesMaster(MinesMId: number): Observable<MinesMasterModel> {
		const url = `${API_CUSTOMERS_URL}${MinesMId}`;
		return this.http.delete<MinesMasterModel>(url);
	}
}
