// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/crud';

import { Global } from '../../../../shared/global';
// Models
import { CompanyMasterModel } from '../_models/companyMaster.model';
import { mergeMap } from 'rxjs/operators';

const API_CUSTOMERS_URL = Global.BASE_COMPANY_MASTER_ENDPOINT;

@Injectable()
export class CompanyMasterService {
	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	// CREATE =>  POST: add a new companyMaster to the server
	createCompanyMaster(companyMaster: CompanyMasterModel): Observable<CompanyMasterModel> {
		if (companyMaster.ImageFile != null) {
			this.uploadImage(companyMaster.ImageFile, companyMaster.PhotoName).subscribe(res => {
				if (res) {
					//console.log(res);
				} else {
					console.log('There is somthing wrong image was not uploaded');
					//this.store.dispatch(new ContractorErrorMessage({ _errorMessage: resValue[0].value }));
				}

			});
		}
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<CompanyMasterModel>(API_CUSTOMERS_URL, companyMaster, { headers: httpHeaders });
	}

	uploadImage(imageFile: File, imageName: any): Observable<any> {
		let formData = new FormData();
		formData.append('ImageFile', imageFile, imageFile.name);
		formData.append('imageName', imageName);

		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'multipart/form-data');

		const url = Global.BASE_UPLOAD_COMPANY_LOGO_IMAGE_ENDPOINT;
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post<any>(url, formData, { headers: httpHeaders });
	}

	// READ
	getAllCompanyMasters(): Observable<CompanyMasterModel[]> {
		return this.http.get<CompanyMasterModel[]>(API_CUSTOMERS_URL);
	}

	getCompanyMasterById(CompanyMId: number): Observable<CompanyMasterModel> {
		return this.http.get<CompanyMasterModel>(API_CUSTOMERS_URL + `/${CompanyMId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findCompanyMasters(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		const url = API_CUSTOMERS_URL;
		return this.http.get<QueryResultsModel[]>(url).pipe(
			mergeMap(res => {
				const result = this.httpUtils.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}

	// UPDATE => PUT: update the companyMaster on the server
	updateCompanyMaster(CompanyMId: number, companyMaster: CompanyMasterModel): Observable<any> {
		if (companyMaster.ImageFile != null) {
			this.uploadImage(companyMaster.ImageFile, companyMaster.PhotoName).subscribe(res => {
				if (res) {
					//console.log(res);
				} else {
					console.log('There is somthing wrong image was not uploaded');
					//this.store.dispatch(new ContractorErrorMessage({ _errorMessage: resValue[0].value }));
				}

			});
		}
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_CUSTOMERS_URL + CompanyMId, companyMaster, { headers: httpHeader });
	}

	// DELETE => delete the companyMaster from the server
	deleteCompanyMaster(CompanyMId: number): Observable<CompanyMasterModel> {
		const url = `${API_CUSTOMERS_URL}${CompanyMId}`;
		return this.http.delete<CompanyMasterModel>(url);
	}
}
