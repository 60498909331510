import { BaseModel } from '../../../_base/crud';

export class CustomerRateModel extends BaseModel {
	CustRateId: number;
	CompanyMId: number;
	CustId: number;
	ProdCatMId: number;
	ProductRate: number;


	clear() {
		this.CompanyMId = undefined;
		this.CustId = undefined;
		this.ProdCatMId = undefined;
		this.ProductRate = 0.00;
	}
}
