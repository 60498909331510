import { QueryParamsModel } from '../../../_base/crud/models/query-models/query-params.model';
import { forkJoin } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
// RxJS
import { mergeMap, map, tap, delay } from 'rxjs/operators';
// NGRX
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryResultsModel } from '../../../_base/crud';
// Services
import { ContractorService } from '../_services/contractor.service';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';
// State
import { AppState } from '../../../../core/reducers';
// Actions
import {
    ContractorActionTypes,
    ContractorPageRequested,
    ContractorPageLoaded,
    OneContractorDeleted,
    ContractorActionToggleLoading,
    ContractorPageToggleLoading,
    ContractorUpdated,
    ContractorCreated,
    ContractorOnServerCreated
} from '../_actions/contractor.actions';
import { of } from 'rxjs';

@Injectable()
export class ContractorEffects {
    showPageLoadingDistpatcher = new ContractorPageToggleLoading({ isLoading: true });
    showActionLoadingDistpatcher = new ContractorActionToggleLoading({ isLoading: true });
    hideActionLoadingDistpatcher = new ContractorActionToggleLoading({ isLoading: false });

    @Effect()
    loadContractorPage$ = this.actions$.pipe(
        ofType<ContractorPageRequested>(ContractorActionTypes.ContractorPageRequested),
        mergeMap(({ payload }) => {
            this.store.dispatch(this.showPageLoadingDistpatcher);
            const requestToServer = this.contractorService.findContractors(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: QueryResultsModel = response[0];
            const lastQuery: QueryParamsModel = response[1];

            this.sharedService.updateContractorList(result.items);
            //console.log(result.items);
            const pageLoadedDispatch = new ContractorPageLoaded({
                contractors: result.items,
                totalCount: result.totalCount,
                page: lastQuery
            });
            return pageLoadedDispatch;
        })
    );

    @Effect()
    deleteContractor$ = this.actions$
        .pipe(
            ofType<OneContractorDeleted>(ContractorActionTypes.OneContractorDeleted),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.contractorService.deleteContractor(payload.ContractorMId);
            }
            ),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    @Effect()
    updateContractor$ = this.actions$
        .pipe(
            ofType<ContractorUpdated>(ContractorActionTypes.ContractorUpdated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.contractorService.updateContractor(payload.contractor.ContractorMId, payload.contractor);
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            })
        );

    @Effect()
    createContractor$ = this.actions$
        .pipe(
            ofType<ContractorOnServerCreated>(ContractorActionTypes.ContractorOnServerCreated),
            mergeMap(({ payload }) => {
                this.store.dispatch(this.showActionLoadingDistpatcher);
                return this.contractorService.createContractor(payload.contractor).pipe(
                    tap(res => {
                        //console.log(res);
                        //const resValue = Object.keys(res).map(key => res[key]);
                        //console.log(resValue[0].value);
                        //this.store.dispatch(new ContractorCreated({ contractor: res }));
                        if (res.ContractorMId == 0) {
                            this.store.dispatch(new ContractorCreated({ contractor: res }));
                        } else {
                            console.log('There is somthing wrong');
                            //this.store.dispatch(new ContractorErrorMessage({ _errorMessage: resValue[0].value }));
                        }

                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDistpatcher;
            }),
        );

    constructor(private actions$: Actions, private contractorService: ContractorService,
        private sharedService: ShareddataserviceService, private store: Store<AppState>) { }
}
