import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel, HttpUtilsService } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Global } from '../../../shared/global';
import { ShareddataserviceService } from '../../../shared/shareddataservice.service';
import { some, each, filter } from 'lodash';

const API_USERS_URL = 'api/users';
const rootUrl = Global.BASE_LOGIN_ENDPOINT;
const API_PERMISSION_URL = 'api/permissions';
const API_ROLES_URL = 'api/roles';
const API_URL = Global.BASE_USER_MSTER_BY_COMPANYMID;
const API_CUSTOMERS_URL = Global.BASE_USER_MSTER_ENDPOINT;

@Injectable()
export class AuthService {
    loggedCompanyMId: number;

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService,
        private sharedService: ShareddataserviceService) { }

    getLoggedCompanyMId() {
        this.sharedService.companydata.subscribe(data => this.loggedCompanyMId = data[0]["CompanyMId"]);
    }
    // Authentication/Authorization
    login(UserName: string, password: string): Observable<any> {
        //return this.http.post<User>(API_USERS_URL, { email, password });
        const data = 'username=' + UserName + '&password=' + password + '&grant_type=password';
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
        return this.http.post<any>(rootUrl + 'token', data, { headers: reqHeader });
    }

    userAuthentication(userName: any, password: any) {
        const data = 'username=' + userName + '&password=' + password + '&grant_type=password';
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
        return this.http.post(rootUrl + 'token', data, { headers: reqHeader });
    }

    getUserByToken(): Observable<User> {
        const userToken = sessionStorage.getItem('token');
        const userid = sessionStorage.getItem('userID');
        const httpHeaders = new HttpHeaders();
        httpHeaders.append('Authorization', 'Bearer ' + userToken);
        const url = Global.BASE_USER_BY_TOKEN_ENDPOINT;
        return this.http.get<User>(API_CUSTOMERS_URL + +userid, { headers: httpHeaders });
    }

    register(user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders })
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    public requestPassword(email: string): Observable<any> {
        return this.http.get(API_USERS_URL + '/forgot?=' + email)
            .pipe(catchError(this.handleError('forgot-password', []))
            );
    }


    getAllUsers(): Observable<User[]> {
        this.getLoggedCompanyMId();
        const URL = API_URL + this.loggedCompanyMId;
        return this.http.get<User[]>(URL);
    }

    getUserById(userId: number): Observable<User> {
        return this.http.get<User>(API_CUSTOMERS_URL + `/${userId}`);
    }


    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${API_CUSTOMERS_URL}/${userId}`;
        return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    updateUser(UserId: number, _user: User): Observable<any> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.put(API_CUSTOMERS_URL + UserId, _user, { headers: httpHeaders });
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<User> {
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        return this.http.post<User>(API_CUSTOMERS_URL, user, { headers: httpHeaders });
    }

    // // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // // items => filtered/sorted result
    // findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    //     const httpHeaders = new HttpHeaders();
    //     httpHeaders.set('Content-Type', 'application/json');
    //     return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
    // }
    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    // Server should return filtered/sorted result
    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

        this.getLoggedCompanyMId();
        const URL = API_URL + this.loggedCompanyMId;
        return this.http.get<QueryResultsModel[]>(URL).pipe(
            mergeMap(res => {
                const result = this.httpUtils.baseFilter(res, queryParams);
                return of(result);
            })
        );
    }

    // Permissions
    getAllPermissions(): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        const allRolesRequest = this.http.get<Permission[]>(API_PERMISSION_URL);
        const roleRequest = roleId ? this.getRoleById(roleId) : of(null);
        return forkJoin(allRolesRequest, roleRequest).pipe(
            map(res => {
                const _allPermissions: Permission[] = res[0];
                const _role: Role = res[1];
                if (!_allPermissions || _allPermissions.length === 0) {
                    return [];
                }

                const _rolePermission = _role ? _role.permissions : [];
                const result: Permission[] = this.getRolePermissionsTree(_allPermissions, _rolePermission);
                return result;
            })
        );
    }

    private getRolePermissionsTree(_allPermission: Permission[] = [], _rolePermissionIds: number[] = []): Permission[] {
        const result: Permission[] = [];
        const _root: Permission[] = filter(_allPermission, (item: Permission) => !item.parentId);
        each(_root, (_rootItem: Permission) => {
            _rootItem._children = [];
            _rootItem._children = this.collectChildrenPermission(_allPermission, _rootItem.id, _rolePermissionIds);
            _rootItem.isSelected = (some(_rolePermissionIds, (id: number) => id === _rootItem.id));
            result.push(_rootItem);
        });
        return result;
    }

    private collectChildrenPermission(_allPermission: Permission[] = [],
        _parentId: number, _rolePermissionIds: number[] = []): Permission[] {
        const result: Permission[] = [];
        const _children: Permission[] = filter(_allPermission, (item: Permission) => item.parentId === _parentId);
        if (_children.length === 0) {
            return result;
        }

        each(_children, (_childItem: Permission) => {
            _childItem._children = [];
            _childItem._children = this.collectChildrenPermission(_allPermission, _childItem.id, _rolePermissionIds);
            _childItem.isSelected = (some(_rolePermissionIds, (id: number) => id === _childItem.id));
            result.push(_childItem);
        });
        return result;
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number): Observable<Role> {
        return this.http.get<Role>(API_ROLES_URL + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
    createRole(role: Role): Observable<Role> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders });
    }

    // UPDATE => PUT: update the role on the server
    updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
    }

    // DELETE => delete the role from the server
    deleteRole(roleId: number): Observable<Role> {
        const url = `${API_ROLES_URL}/${roleId}`;
        return this.http.delete<Role>(url);
    }

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        return this.http.get<Role[]>(API_ROLES_URL).pipe(
            mergeMap(res => {
                const result = this.httpUtils.baseFilter(res, queryParams, []);
                return of(result);
            })
        );
    }

    // findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    //     // This code imitates server calls
    //     const httpHeaders = new HttpHeaders();
    //     httpHeaders.set('Content-Type', 'application/json');
    //     return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders });
    // }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
