// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// RxJS
import { Observable, of, from } from 'rxjs';
// CRUD
import { HttpUtilsService, QueryParamsModel, QueryResultsModel } from '../../../_base/crud';

import { Global } from '../../../../shared/global';
// Models
import { ContractorModel } from '../_models/contractor.model';
import { mergeMap } from 'rxjs/operators';
import { ShareddataserviceService } from '../../../../shared/shareddataservice.service';

const API_CUSTOMERS_URL = Global.BASE_CONTRACTOR_ENDPOINT;
const API_URL = Global.BASE_CONTRACTOR_BY_COMPANYMID;

@Injectable()
export class ContractorService {

	loggedCompanyMId: number;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService,
		private sharedService: ShareddataserviceService
	) { }

	getLoggedCompanyMId() {
		this.sharedService.companydata.subscribe(data => this.loggedCompanyMId = data[0]["CompanyMId"]);
	}

	// CREATE =>  POST: add a new contractor to the server
	createContractor(contractor: ContractorModel): Observable<ContractorModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		return this.http.post<ContractorModel>(API_CUSTOMERS_URL, contractor, { headers: httpHeaders });
	}

	uploadImage(imageFile: File, imageName: any): Observable<any> {
		let formData = new FormData();
		formData.append('ImageFile', imageFile, imageFile.name);
		formData.append('imageName', imageName);

		const httpHeaders = new HttpHeaders();
		httpHeaders.set('Content-Type', 'multipart/form-data');

		const url = "Global.BASE_UPLOAD_CATEGORY_IMAGE_ENDPOINT";
		// Note: Add headers if needed (tokens/bearer)
		return this.http.post<any>(url, formData, { headers: httpHeaders });
	}

	// READ
	getAllContractors(): Observable<ContractorModel[]> {
		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;
		return this.http.get<ContractorModel[]>(URL);
	}

	getContractorById(ContractorMId: number): Observable<ContractorModel> {
		return this.http.get<ContractorModel>(API_CUSTOMERS_URL + `/${ContractorMId}`);
	}

	// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
	// items => filtered/sorted result
	// Server should return filtered/sorted result
	findContractors(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
		// Note: Add headers if needed (tokens/bearer)
		const httpHeaders = this.httpUtils.getHTTPHeaders();
		const httpParams = this.httpUtils.getFindHTTPParams(queryParams);

		this.getLoggedCompanyMId();
		const URL = API_URL + this.loggedCompanyMId;

		return this.http.get<QueryResultsModel[]>(URL).pipe(
			mergeMap(res => {
				console.log(res);
				const result = this.httpUtils.baseFilter(res, queryParams);
				return of(result);
			})
		);
	}

	// UPDATE => PUT: update the contractor on the server
	updateContractor(ContractorMId: number, contractor: ContractorModel): Observable<any> {
		const httpHeader = this.httpUtils.getHTTPHeaders();
		return this.http.put(API_CUSTOMERS_URL + ContractorMId, contractor, { headers: httpHeader });
	}

	// DELETE => delete the contractor from the server
	deleteContractor(ContractorMId: number): Observable<ContractorModel> {
		const url = `${API_CUSTOMERS_URL}${ContractorMId}`;
		return this.http.delete<ContractorModel>(url);
	}
}
