// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { TransporterActions, TransporterActionTypes } from '../_actions/transporter.actions';
// Models
import { TransporterModel } from '../_models/transporter.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface TransporterState extends EntityState<TransporterModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedTransportMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<TransporterModel> = createEntityAdapter<TransporterModel>({
    selectId: transporter => transporter.TransportMId
});

export const initialTransporterState: TransporterState = adapter.getInitialState({
    transporterForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedTransportMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function transporterReducer(state = initialTransporterState, action: TransporterActions): TransporterState {
    switch (action.type) {
        case TransporterActionTypes.TransporterPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedTransportMId: undefined
            };
        }
        case TransporterActionTypes.TransporterActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case TransporterActionTypes.TransporterOnServerCreated: return {
            ...state
        };
        case TransporterActionTypes.TransporterCreated: return adapter.addOne(action.payload.transporter, {
            ...state, lastCreatedTransporterId: action.payload.transporter.TransportMId
        });
        case TransporterActionTypes.TransporterUpdated: return adapter.updateOne(action.payload.partialTransporter, state);
        case TransporterActionTypes.OneTransporterDeleted: return adapter.removeOne(action.payload.TransportMId, state);
        case TransporterActionTypes.TransporterPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case TransporterActionTypes.TransporterPageLoaded: {
            return adapter.addMany(action.payload.transporters, {
                ...initialTransporterState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getTransporterState = createFeatureSelector<TransporterModel>('transporters');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
