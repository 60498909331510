import { BaseModel } from '../../../_base/crud';

export class UserMasterModel extends BaseModel {
	UserId: number;
	CompanyMId: number;
	LoginName: string;
	UserName: string;
	Pwd: string;
	Role: string;
	AddINfo1: string;
	AddINfo2: string;
	accessToken: string;


	clear() {
		this.CompanyMId= undefined;
		this.LoginName= '';
		this.UserName= '';
		this.Pwd= '';
		this.Role= '';
		this.AddINfo1= 'true';
		this.AddINfo2= '';
		this.accessToken = '';
	}
}
