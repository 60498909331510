// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../../_base/crud';
// State
import { DeliveryChallanState } from '../_reducers/deliveryChallan.reducers';
import { DeliveryChallanModel } from '../_models/deliveryChallan.model';

export const selectDeliveryChallanState = createFeatureSelector<DeliveryChallanState>('deliveryChallans');

export const selectDeliveryChallanById = (DeliveryChallanMId: number) => createSelector(
    selectDeliveryChallanState,
    deliveryChallanState => deliveryChallanState.entities[DeliveryChallanMId]
);

export const selectDeliveryChallanPageLoading = createSelector(
    selectDeliveryChallanState,
    deliveryChallanState => deliveryChallanState.listLoading
);

export const selectDeliveryChallanActionLoading = createSelector(
    selectDeliveryChallanState,
    deliveryChallanState => deliveryChallanState.actionsloading
);

export const selectLastCreatedDeliveryChallanMId = createSelector(
    selectDeliveryChallanState,
    deliveryChallanState => deliveryChallanState.lastCreatedDCMId
);

export const selectDeliveryChallanShowInitWaitingMessage = createSelector(
    selectDeliveryChallanState,
    deliveryChallanState => deliveryChallanState.showInitWaitingMessage
);

export const selectDeliveryChallanInStore = createSelector(
    selectDeliveryChallanState,
    deliveryChallanState => {
        const items: DeliveryChallanModel[] = [];
        each(deliveryChallanState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: DeliveryChallanModel[] = httpExtension.sortArray(items, deliveryChallanState.lastQuery.sortField, deliveryChallanState.lastQuery.sortOrder);
        return new QueryResultsModel(result, deliveryChallanState.totalCount, '');
    }
);
