import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/catch';
//import { map } from 'rxjs/operators';
import { Global } from '../../../../../shared/global';

@Injectable()
export class FyearService {

  constructor(private _http: HttpClient) { }

  conString(dbName: any) {
    //const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });  
    const URL = Global.BASE_CONNECTION_STRING_ENDPOINT;
    const body = JSON.stringify(dbName);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(URL, body, { headers: headers });
  }

  private handleError(error: Response) {
    console.error(error);
    return 'Server error';
  }
}
