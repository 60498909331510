// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { TransporterModel } from '../_models/transporter.model';

export enum TransporterActionTypes {
    TransporterOnServerCreated = '[Edit Transporter Dialog] Transporter On Server Created',
    TransporterCreated = '[Edit Transporter Dialog] Transporter Created',
    TransporterUpdated = '[Edit Transporter Dialog] Transporter Updated',
    OneTransporterDeleted = '[Transporter List Page] One Transporter Deleted',
    TransporterPageRequested = '[Transporter List Page] Transporter Page Requested',
    TransporterPageLoaded = '[Transporter API] Transporter Page Loaded',
    TransporterPageCancelled = '[Transporter API] Transporter Page Cancelled',
    TransporterPageToggleLoading = '[Transporter] Transporter Page Toggle Loading',
    TransporterActionToggleLoading = '[Transporter] Transporter Action Toggle Loading'
}

export class TransporterOnServerCreated implements Action {
    readonly type = TransporterActionTypes.TransporterOnServerCreated;
    constructor(public payload: { transporter: TransporterModel }) { }
}

export class TransporterCreated implements Action {
    readonly type = TransporterActionTypes.TransporterCreated;
    constructor(public payload: { transporter: TransporterModel }) { }
}

export class TransporterUpdated implements Action {
    readonly type = TransporterActionTypes.TransporterUpdated;
    constructor(public payload: { 
        TransportMId: number,
        partialTransporter: Update<TransporterModel>, // For State update
        transporter: TransporterModel 
    }) { }
}

export class OneTransporterDeleted implements Action {
    readonly type = TransporterActionTypes.OneTransporterDeleted;
    constructor(public payload: { TransportMId: number }) { }
}

export class TransporterPageRequested implements Action {
    readonly type = TransporterActionTypes.TransporterPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class TransporterPageLoaded implements Action {
    readonly type = TransporterActionTypes.TransporterPageLoaded;
    constructor(public payload: { transporters: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class TransporterPageCancelled implements Action {
    readonly type = TransporterActionTypes.TransporterPageCancelled;
}

export class TransporterPageToggleLoading implements Action {
    readonly type = TransporterActionTypes.TransporterPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class TransporterActionToggleLoading implements Action {
    readonly type = TransporterActionTypes.TransporterActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type TransporterActions = TransporterOnServerCreated
    | TransporterCreated
    | TransporterUpdated
    | OneTransporterDeleted
    | TransporterPageRequested
    | TransporterPageLoaded
    | TransporterPageCancelled
    | TransporterPageToggleLoading
    | TransporterActionToggleLoading;
