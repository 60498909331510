// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject, throwError } from 'rxjs';
import { finalize, takeUntil, tap, catchError } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../../core/auth';
import { FyearService } from './fyear.service';
import { FYearModel } from './fyear.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ShareddataserviceService } from '../../../../../shared/shareddataservice.service';

const DEMO_PARAMS = {
  FinancialYear: '',
  ModuleType: '',
};

@Component({
  selector: 'kt-financial-year',
  templateUrl: './financial-year.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FinancialYearComponent implements OnInit, OnDestroy {
  // Public params
  fyearForm: FormGroup;
  loading = false;
  comloading = false;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  fYearModel = new FYearModel();

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
  constructor(
    private router: Router,
    private auth: AuthService,
    private fyearService: FyearService,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private sharedDataService: ShareddataserviceService,
  ) {
    this.unsubscribe = new Subject();
  }

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
  ngOnInit(): void {
    this.initLoginForm();
  }

	/**
	 * On destroy
	 */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
    //this.comloading = false;
  }

	/**
	 * Form initalization
	 * Default params, validators
	 */
  initLoginForm() {
    this.fyearForm = this.fb.group({
      FinancialYear: [DEMO_PARAMS.FinancialYear, Validators.compose([Validators.required])],
      ModuleType: [DEMO_PARAMS.ModuleType]
    });
  }

	/**
	 * Form Submit
	 */
  submit() {
    const controls = this.fyearForm.controls;
    /** check form */
    if (this.fyearForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    this.loading = true;

    const FYearData = {
      FinancialYear: controls['FinancialYear'].value,
      ModuleType: controls['ModuleType'].value
    };

    //sessionStorage.setItem('ModuleType', FYearData.ModuleType);
    if (FYearData.FinancialYear === 'inaatsc_Silica1819') {
      sessionStorage.setItem('FinancialYear', '2018 to 2019');
    } else if (FYearData.FinancialYear === 'inaatsc_Silica1920') {
      sessionStorage.setItem('FinancialYear', '2019 to 2020');
    } else if (FYearData.FinancialYear === 'inaatsc_Silica2021') {
      sessionStorage.setItem('FinancialYear', '2020 to 2021');
    } else if (FYearData.FinancialYear === 'inaatsc_Silica2122') {
      sessionStorage.setItem('FinancialYear', '2021 to 2022');
    }
    this.fYearModel.DbName = FYearData.FinancialYear;
    //sessionStorage.setItem('DbName', this.fYearModel.DbName);
    this.fyearService
      .conString(this.fYearModel)
      .pipe(
        tap(user => {
          if (!user) {
            this.authNoticeService.setNotice('Opps financial year not found!', 'danger');
          } else {
            setTimeout(() => {
              this.router.navigateByUrl('/auth/login'); // Login
            }, 2000);

          }
        },
          error => {
            this.authNoticeService.setNotice('Opps server is down or check your internet connection!', 'danger');
          }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }
	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.fyearForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
