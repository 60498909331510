// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { CustomerRateActions, CustomerRateActionTypes } from '../_actions/customerRate.actions';
// Models
import { CustomerRateModel } from '../_models/customerRate.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface CustomerRateState extends EntityState<CustomerRateModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedCustRateId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<CustomerRateModel> = createEntityAdapter<CustomerRateModel>({
    selectId: customerRate => customerRate.CustRateId
});

export const initialCustomerRateState: CustomerRateState = adapter.getInitialState({
    customerRateForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedCustRateId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function customerRateReducer(state = initialCustomerRateState, action: CustomerRateActions): CustomerRateState {
    switch (action.type) {
        case CustomerRateActionTypes.CustomerRatePageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedCustRateId: undefined
            };
        }
        case CustomerRateActionTypes.CustomerRateActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case CustomerRateActionTypes.CustomerRateOnServerCreated: return {
            ...state
        };
        case CustomerRateActionTypes.CustomerRateCreated: return adapter.addOne(action.payload.customerRate, {
            ...state, lastCreatedCustRateId: action.payload.customerRate.CustRateId
        });
        case CustomerRateActionTypes.CustomerRateUpdated: return adapter.updateOne(action.payload.partialCustomerRate, state);
        case CustomerRateActionTypes.CustomerRateStatusUpdated: {
            const _partialUserReviews: Update<CustomerRateModel>[] = [];
            // tslint:disable-next-line:prefer-const
            for (let i = 0; i < action.payload.customerRate.length; i++) {
                _partialUserReviews.push({
				    id: action.payload.customerRate[i].CustRateId,
				    changes: {
                        ProductRate: action.payload.ProductRate
                    }
			    });
            }
            return adapter.updateMany(_partialUserReviews, state);
        }
        case CustomerRateActionTypes.OneCustomerRateDeleted: return adapter.removeOne(action.payload.CustRateId, state);
        case CustomerRateActionTypes.CustomerRatePageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case CustomerRateActionTypes.CustomerRatePageLoaded: {
            return adapter.addMany(action.payload.customerRates, {
                ...initialCustomerRateState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getCustomerRateState = createFeatureSelector<CustomerRateModel>('customerRates');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
