import { mergeMap, tap } from 'rxjs/operators';
// RxJS
import { delay, distinctUntilChanged, skip, filter, take, map } from 'rxjs/operators';
// NGRX
import { Store, select } from '@ngrx/store';
// CRUD
import { BaseDataSource, QueryResultsModel } from '../../../_base/crud';
// State
import { AppState } from '../../../reducers';
import { selectCompanyMasterInStore, selectCompanyMasterPageLoading, selectCompanyMasterShowInitWaitingMessage } from '../_selectors/companyMaster.selectors';

export class CompanyMasterDataSource extends BaseDataSource {
	constructor(private store: Store<AppState>) {
		super();

		this.loading$ = this.store.pipe(
			select(selectCompanyMasterPageLoading),
		);

		this.isPreloadTextViewed$ = this.store.pipe(
			select(selectCompanyMasterShowInitWaitingMessage)
		);

		this.store.pipe(
			select(selectCompanyMasterInStore),
		).subscribe((response: QueryResultsModel) => {
			this.paginatorTotalSubject.next(response.totalCount);
			this.entitySubject.next(response.items);
		});
	}
}
