// NGRX
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// CRUD
import { QueryParamsModel } from '../../../_base/crud';
// Models
import { UserMasterModel } from '../_models/userMaster.model';

export enum UserMasterActionTypes {
    UserMasterOnServerCreated = '[Edit UserMaster Dialog] UserMaster On Server Created',
    UserMasterCreated = '[Edit UserMaster Dialog] UserMaster Created',
    UserMasterUpdated = '[Edit UserMaster Dialog] UserMaster Updated',
    OneUserMasterDeleted = '[UserMaster List Page] One UserMaster Deleted',
    UserMasterPageRequested = '[UserMaster List Page] UserMaster Page Requested',
    UserMasterPageLoaded = '[UserMaster API] UserMaster Page Loaded',
    UserMasterPageCancelled = '[UserMaster API] UserMaster Page Cancelled',
    UserMasterPageToggleLoading = '[UserMaster] UserMaster Page Toggle Loading',
    UserMasterActionToggleLoading = '[UserMaster] UserMaster Action Toggle Loading'
}

export class UserMasterOnServerCreated implements Action {
    readonly type = UserMasterActionTypes.UserMasterOnServerCreated;
    constructor(public payload: { userMaster: UserMasterModel }) { }
}

export class UserMasterCreated implements Action {
    readonly type = UserMasterActionTypes.UserMasterCreated;
    constructor(public payload: { userMaster: UserMasterModel }) { }
}

export class UserMasterUpdated implements Action {
    readonly type = UserMasterActionTypes.UserMasterUpdated;
    constructor(public payload: { 
        UserId: number,
        partialUserMaster: Update<UserMasterModel>, // For State update
        userMaster: UserMasterModel 
    }) { }
}

export class OneUserMasterDeleted implements Action {
    readonly type = UserMasterActionTypes.OneUserMasterDeleted;
    constructor(public payload: { UserId: number }) { }
}

export class UserMasterPageRequested implements Action {
    readonly type = UserMasterActionTypes.UserMasterPageRequested;
    constructor(public payload: { page: QueryParamsModel }) { }
}

export class UserMasterPageLoaded implements Action {
    readonly type = UserMasterActionTypes.UserMasterPageLoaded;
    constructor(public payload: { userMasters: any[], totalCount: number, page: QueryParamsModel }) { }
}

export class UserMasterPageCancelled implements Action {
    readonly type = UserMasterActionTypes.UserMasterPageCancelled;
}

export class UserMasterPageToggleLoading implements Action {
    readonly type = UserMasterActionTypes.UserMasterPageToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export class UserMasterActionToggleLoading implements Action {
    readonly type = UserMasterActionTypes.UserMasterActionToggleLoading;
    constructor(public payload: { isLoading: boolean }) { }
}

export type UserMasterActions = UserMasterOnServerCreated
    | UserMasterCreated
    | UserMasterUpdated
    | OneUserMasterDeleted
    | UserMasterPageRequested
    | UserMasterPageLoaded
    | UserMasterPageCancelled
    | UserMasterPageToggleLoading
    | UserMasterActionToggleLoading;
