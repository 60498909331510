import { BaseModel } from '../../../_base/crud';

export class SiteModel extends BaseModel {
	SiteMId: number;
	CompanyMId: number;
	SiteCode: number;
	SiteName: string;
	SiteOwnerName: string;
	SiteAddress: string;
	SitePincode: string;
	SiteMobileNo: string;
	SitePhoneNo: string;
	SiteFaxNo: string;
	SiteEmailId: string;
	SiteWebsite: string;
	SiteRefPerson1: string;
	SiteRefPerson1Mo: string;
	SiteRefPerson1EmailId: string;
	SiteRefPerson2: string;
	SiteRefPerson2Mo: string;
	SiteRefPerson2EmailId: string;
	SiteAddInfo1: string;
	SiteAddInfo2: string;
	SiteAddInfo3: string;
	SiteAddInfo4: string;


	clear() {
		this.CompanyMId= undefined;
		this.SiteCode= undefined;
		this.SiteName= '';
		this.SiteOwnerName= '';
		this.SiteAddress= '';
		this.SitePincode= '';
		this.SiteMobileNo= '';
		this.SitePhoneNo= '';
		this.SiteFaxNo= '';
		this.SiteEmailId= '';
		this.SiteWebsite= '';
		this.SiteRefPerson1= '';
		this.SiteRefPerson1Mo= '';
		this.SiteRefPerson1EmailId= '';
		this.SiteRefPerson2= '';
		this.SiteRefPerson2Mo= '';
		this.SiteRefPerson2EmailId= '';
		this.SiteAddInfo1= '';
		this.SiteAddInfo2= '';
		this.SiteAddInfo3= '';
		this.SiteAddInfo4= '';
	}
}
