// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { ContractorActions, ContractorActionTypes } from '../_actions/contractor.actions';
// Models
import { ContractorModel } from '../_models/contractor.model';
import { QueryParamsModel } from '../../../_base/crud';

export interface ContractorState extends EntityState<ContractorModel> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedContractorMId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<ContractorModel> = createEntityAdapter<ContractorModel>({
    selectId: contractor => contractor.ContractorMId
});

export const initialContractorState: ContractorState = adapter.getInitialState({
    contractorForEdit: null,
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastCreatedContractorMId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
});

export function contractorReducer(state = initialContractorState, action: ContractorActions): ContractorState {
    switch (action.type) {
        case ContractorActionTypes.ContractorPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedContractorMId: undefined
            };
        }
        case ContractorActionTypes.ContractorActionToggleLoading: {
            return {
                ...state, actionsloading: action.payload.isLoading
            };
        }
        case ContractorActionTypes.ContractorOnServerCreated: return {
            ...state
        };
        case ContractorActionTypes.ContractorCreated: return adapter.addOne(action.payload.contractor, {
            ...state, lastCreatedContractorId: action.payload.contractor.ContractorMId
        });
        case ContractorActionTypes.ContractorUpdated: return adapter.updateOne(action.payload.partialContractor, state);
        case ContractorActionTypes.OneContractorDeleted: return adapter.removeOne(action.payload.ContractorMId, state);
        case ContractorActionTypes.ContractorPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case ContractorActionTypes.ContractorPageLoaded: {
            return adapter.addMany(action.payload.contractors, {
                ...initialContractorState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }
        default: return state;
    }
}

export const getContractorState = createFeatureSelector<ContractorModel>('contractors');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
